<template>
  <v-container class="d-flex justify-center" fluid>
    <v-row class="mt-n13" no-gutters>
      <v-col cols="12">
        <v-card class="pa-4 moholand-shadow rounded-lg">
          <v-card-title>
            تنظیمات
          </v-card-title>

          <v-form ref="form" @submit.prevent="submit" class="mt-2">
            <v-form-base
              :col="{ cols: 12, md: 6 }"
              :model="model"
              :schema="schema"
              class="ma-0"
            />
            <!-- <v-row class="px-2">
              <v-col cols="12" sm="6">
                <div>
                  <div class="grey--text">رمز عبور</div>
                  <v-text-field
                    background-color="grey lighten-2"
                    hide-details="auto"
                    solo
                    flat
                    class="rounded-lg"
                    v-model="model.password"
                  />
                </div>
              </v-col>
              <v-col cols="12" sm="6">
                <div>
                  <div class="grey--text">تکرار رمز عبور</div>
                  <v-text-field
                    background-color="grey lighten-2"
                    hide-details="auto"
                    solo
                    flat
                    class="rounded-lg"
                    v-model="model.passwordRepeat"
                  />
                </div>
              </v-col>
            </v-row> -->
            <!-- <v-row>
              <v-col cols="12" sm="6">
                <div>
                  <div class="grey--text">شماره حساب بانکی</div>
                  <v-text-field
                    background-color="grey lighten-2"
                    hide-details="auto"
                    solo
                    flat
                    class="rounded-lg"
                    v-model="model.accountNum"
                  />
                </div>
              </v-col>
              <v-col cols="12" sm="6">
                <div>
                  <div class="grey--text">شماره کارت بانکی</div>
                  <v-text-field
                    background-color="grey lighten-2"
                    hide-details="auto"
                    solo
                    flat
                    class="rounded-lg"
                    v-model="model.cardNum"
                  />
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div>
                  <div class="grey--text">شماره شبا</div>
                  <v-text-field
                    background-color="grey lighten-2"
                    hide-details="auto"
                    solo
                    flat
                    class="rounded-lg ltr"
                    prefix="IR"
                    v-model="model.shabaNum"
                  />
                </div>
              </v-col>
            </v-row> -->

            <v-row>
              <v-spacer />
              <v-col cols="12" sm="3">
                <v-btn
                  class="my-4 white--text moholand-rounded font-weight-bold"
                  elevation="0"
                  color="orange"
                  block
                  x-large
                  type="submit"
                  :loading="loading"
                >
                  ثبت تغییرات
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import VFormBase from "vuetify-form-base";
import { formPostPrepare } from "@/constants/PanelFormHandler";
export default {
  components: {
    VFormBase,
  },
  data() {
    return {
      loading: false,
      resend: false,
      model: {
        avatar: this.$store.state.userData?.avatar_url || null,
        first_name: this.$store.state.userData?.first_name || "",
        last_name: this.$store.state.userData?.last_name || "",
        cellphone: this.$store.state.userData?.cellphone || "",
        email: this.$store.state.userData?.email || "",
        // password: "",
        // passwordRepeat: "",
        desc: this.$store.state.userData?.desc || "",
      },
      schema: {
        avatar: {
          type: "MyAvatarPicker",
          rules: [Constants.rules.required],
          required: true,
          col: { cols: 12 },
        },
        first_name: {
          type: "CustomInput",
          label: "نام",
          rules: [Constants.rules.required],
          required: true,
        },
        last_name: {
          type: "CustomInput",
          label: "نام خانوادگی",
          rules: [Constants.rules.required],
          required: true,
        },
        cellphone: {
          type: "CustomInput",
          label: "شماره موبایل",
          rules: [Constants.rules.required, Constants.rules.cellphone],
          required: true,
        },
        email: {
          type: "CustomInput",
          label: "ایمیل",
          required: true,
          rules: [Constants.rules.email],
        },
        desc: {
          type: "CustomInput",
          inputType: "textarea",
          label: "درباره من",
          col: { cols: 12 },
        },
      },
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        if (!this.loading) this.loading = true;

        let data = formPostPrepare(this.model);

        MyAxios.post(
          "users/" + this.$store.state.userData?.id + "/update",
          data
        )
          .then((response) => {
            this.loading = false;
            this.$store.commit("setUserData", {
              ...this.$store.state.userData,
              ...response.data,
            });
            this.model.avatar = this.$store.state.userData?.avatar_url || "";
            this.$root.$emit("toast", {
              text: "حساب شما با موفقیت ویرایش شد.",
              type: "success",
            });
            this.resend = false;
            console.log(response);
          })
          .catch((error) => {
            this.loading = false;
            if (error.response.status == 500 && !this.resend) {
              this.model.avatar = "";
              this.resend = true;
              this.submit();
            } else
              this.$root.$emit("toast", {
                text: "مشکلی پیش آمده است. لطفا دوباره سعی کنید",
              });
            console.log(error);
          });
      } else {
        this.$root.$emit("toast", {
          text: "لطفا اطلاعات را به درستی وارد نمایید.",
        });
      }
    },
  },
  computed: {
    checkPasswordRepeat() {
      return (
        this.model.password == this.model.passwordRepeat ||
        "تکرار پسورد با پسوردی که وارد کردید یکسان نیست."
      );
    },
  },
};
</script>

<style scoped>
.ltr {
  direction: ltr;
}
</style>
