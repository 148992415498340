<template>
  <div>
    <settings-customer v-if="role == 'user' || role == 'instructor'" />
    <settings-seller v-else-if="role == 'vendor'" />
  </div>
</template>

<script>
import SettingsSeller from "./Settings_Seller.vue";
import SettingsCustomer from "./Settings_Customer.vue";

export default {
  data() {
    return {
      role: this.$store.state.userData?.roleName || "",
    };
  },
  components: {
    SettingsSeller,
    SettingsCustomer,
  },
};
</script>

<style scoped></style>
